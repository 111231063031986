import { BrowserRouter, Routes, Route } from 'react-router-dom'
import Header from './components/Header'
import Footer from './components/Footer'
import Home from './home'
import ContactUs from './contact-us'
import WorkWithUs from './staffing'
import TerminosCondiciones from './components/Footer/TerminosCondiciones'
import WhatsAppButton from "./components/Header/WhatsAppButton";
import NoticePrivacity from "./components/InstitutionalPages/NoticePrivacity/NoticePrivacity";
import { useDispatch } from 'react-redux'
import { setLanguage, setHost } from "./reducers/languageSlice";
import './App.css'
import 'bootstrap/dist/css/bootstrap.min.css'


function App({ language, host }) {
  const dispatch = useDispatch()
  
  dispatch(setLanguage(language))
  dispatch(setHost(host))
  
  return (
    <BrowserRouter>
      <div className="App">
        <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="contact-us" element={<ContactUs />} />
          <Route path="staffing" element={<WorkWithUs />} />
          <Route path="terms&conditions" element={<TerminosCondiciones />}/>
          <Route path="aviso-de-privacidad" element={<NoticePrivacity />}/>
        </Routes>
        {host !== 'www.vinneren.com'? <WhatsAppButton /> : <></>}
        <Footer />
      </div>
    </BrowserRouter>
  )
}

export default App
